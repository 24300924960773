import { db } from './firebase';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Papa from 'papaparse';

export const preprocessAndUploadCSV = (file) => {
  
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    console.error("No user is signed in.");
    return;
  }

  const userId = user.email;
  console.log("User ID:", userId);

  Papa.parse(file, {
    header: true,
    complete: async (results) => {
      const data = results.data;
      console.log("Parsed CSV data:", data);

      let portfolioDate = '';

      for (const item of data) {
        try {
          const {
            'COB Date': COBDate,
            Symbol,
            'Security Description': SecurityDescription,
            'Acquisition Date': AcquisitionDate,
            Quantity,
            'Unit Cost ($)': UnitCost,
            'Cost Basis ($)': CostBasis,
            'Value ($)': Value
          } = item;

          console.log("Processing item:", item);

          // Extract and format COB Date
          if (!portfolioDate && COBDate) {
            portfolioDate = new Date(COBDate).toISOString().split('T')[0];
            console.log("Extracted COB Date:", portfolioDate);
          }

          // Convert date string to Firestore Timestamp
          const acquisitionDate = new Date(AcquisitionDate);
          const formattedAcquisitionDate = acquisitionDate.toISOString().split('T')[0];

          // Document ID combining Symbol and Acquisition Date
          const docId = `${Symbol}_${formattedAcquisitionDate}`;
          console.log("Generated Document ID:", docId);

          // Add users/${userId}/portfolios/${portfolioDate} document to Firestore and give it a value "default = """
          await setDoc(doc(db, `users/${userId}/portfolios/${portfolioDate}`), {
              default: ""
          });

          // Add document to Firestore
          await setDoc(doc(db, `users/${userId}/portfolios/${portfolioDate}/stocks/${docId}`), {
            Symbol,
            SecurityDescription,
            AcquisitionDate: Timestamp.fromDate(acquisitionDate),
            Quantity: Number(Quantity),
            UnitCost: Number(UnitCost.replace(/,/g, '')),
            CostBasis: Number(CostBasis.replace(/,/g, '')),
            Value: Number(Value.replace(/,/g, ''))
          });

          console.log("Document written to Firestore: ", docId);
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    },
    error: (error) => {
      console.error("Error parsing CSV:", error);
    }
  });
};
