// src/pages/Allocations/Allocations.js
import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../util/firebase';
import './AllocationsTab.css';

const AllocationsTab = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPortfolios = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is signed in.");
        return;
      }

      const userId = user.email;
      const portfoliosRef = collection(db, `users/${userId}/portfolios`);
      const portfoliosSnapshot = await getDocs(portfoliosRef);
      
      if (portfoliosSnapshot.empty) {
        console.log("No portfolios found.");
      } else {
        const portfoliosList = [];
        for (const doc of portfoliosSnapshot.docs) {
          const stocksRef = collection(db, `users/${userId}/portfolios/${doc.id}/stocks`);
          const stocksSnapshot = await getDocs(stocksRef);

          const stocksList = [];
          for (const stockDoc of stocksSnapshot.docs) {
            stocksList.push(stockDoc.data());
          }

          portfoliosList.push({
            id: doc.id,
            stocks: stocksList,
          });
        }
        setPortfolios(portfoliosList);
      }

      setLoading(false);
    };

    fetchPortfolios();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  // Get the most recent portfolio
  const recentPortfolio = portfolios.length > 0 ? portfolios[portfolios.length - 1] : null;

  const portfolioValue = recentPortfolio ? recentPortfolio.stocks.reduce((acc, stock) => acc + stock.Value, 0) : 0;

  return (
    <div className="allocations">
      <div className="portfolio-summary">
        <h2>Current Portfolio Value</h2>
        <p>${portfolioValue.toFixed(2)}</p>
      </div>
      <div className="portfolio-table">
        {recentPortfolio ? (
          <div key={recentPortfolio.id}>
            <h2>{recentPortfolio.id}</h2>
            <table>
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Security Description</th>
                  <th>Quantity</th>
                  <th>Unit Cost</th>
                  <th>Cost Basis</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {recentPortfolio.stocks.map((stock, index) => (
                  <tr key={index}>
                    <td>{stock.Symbol}</td>
                    <td>{stock.SecurityDescription}</td>
                    <td>{stock.Quantity}</td>
                    <td>{stock.UnitCost}</td>
                    <td>{stock.CostBasis}</td>
                    <td>{stock.Value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No portfolio data available</p>
        )}
      </div>
    </div>
  );
};

export default AllocationsTab;
