// src/pages/DashboardPage/DashboardPage.js
import React, { useState } from 'react';
import Allocations from '../AllocationsTab/AllocationsTab';
import Settings from '../SettingsTab/SettingsTab';
import Playground from '../PlaygroundTab/PlaygroundTab';
import './DashboardPage.css';

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState('allocations');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'allocations':
        return <Allocations />;
      case 'settings':
        return <Settings />;
      case 'playground':
        return <Playground />;
      default:
        return <div>Tab Content Not Found</div>;
    }
  };

  return (
    <div className="dashboard-page">
      <nav className="tab-nav">
        <button onClick={() => setActiveTab('allocations')}>Allocations</button>
        <button onClick={() => setActiveTab('playground')}>Playground</button>
        <button onClick={() => setActiveTab('settings')}>Settings</button>
      </nav>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default DashboardPage;
