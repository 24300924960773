// src/util/firebase.js

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIxJBNQXD438Eroy4RQFp3RYwBIvjLR-g",
  authDomain: "portfolio-tracker-15dde.firebaseapp.com",
  projectId: "portfolio-tracker-15dde",
  storageBucket: "portfolio-tracker-15dde.appspot.com",
  messagingSenderId: "1083125342563",
  appId: "1:1083125342563:web:0f2d7696b6d941d6217356",
  measurementId: "G-F37Y92B249"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { db, auth, provider };