// src/pages/Settings/Settings.js
import React, { useState } from 'react';
import { preprocessAndUploadCSV } from '../../util/preprocess_and_upload_csv';
import './SettingsTab.css';

const Settings = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      preprocessAndUploadCSV(file);
    }
  };

  return (
    <div className="settings">
      <h2>Upload Portfolio CSV</h2>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload CSV</button>
    </div>
  );
};

export default Settings;
