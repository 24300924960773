import React from 'react';
import SignInButton from '../../util/SignInButton';

const LandingPage = () => {
  return (
    <div>
      <h1>Welcome to My Portfolio Tracker</h1>
      <SignInButton />
    </div>
  );
};

export default LandingPage;
