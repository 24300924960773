// src/SignInButton.js
import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const SignInButton = () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('Signed in with Google:', result.user);
      })
      .catch((error) => {
        console.error('Error signing in with Google:', error);
      });
  };

  return (
    <button onClick={handleSignIn}>
      Sign in with Google
    </button>
  );
};

export default SignInButton;